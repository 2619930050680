import { createBrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";
import Reports from "../components/reports";

//Utils
import { webRoutes } from "./web";

//Components
import AuthLayout from "../components/auth/AuthLayout";
import Login from "../components/auth/Login";
import ErrorPage from "../components/error/ErrorPage";
import ProgressBar from "../components/loader/ProgressBar";
import Layout from "../components/layout";
import UserDetail from "../components/user_detail";
import Redirect from "../components/layout/Redirect";
import RequireAuth from "./RequireAuth";
import NotFoundPage from "../components/error/NotFoundPage";
import Settings from "../components/settings";
import Companies from "../components/companies";
import LeiImportCompanies from "../components/leiimportcompanies";
import FullFlow from "../components/full_flow";

//Load Pages
const Dashboard = loadable(() => import("../components/dashboard"), {
  fallback: <ProgressBar />,
});
const Users = loadable(() => import("../components/users"), {
  fallback: <ProgressBar />,
});
const Customers = loadable(() => import("../components/customers"), {
  fallback: <ProgressBar />,
});
const CustomerDetail = loadable(() => import("../components/customer_detail"), {
  fallback: <ProgressBar />,
});

const UploadDocumentLlama = loadable(
  () => import("../components/upload_document_llama"),
  {
    fallback: <ProgressBar />,
  }
);

const Processes = loadable(() => import("../components/processes"), {
  fallback: <ProgressBar />,
});

const Documents = loadable(() => import("../components/documents"), {
  fallback: <ProgressBar />,
});

export const browserRouter = createBrowserRouter([
  {
    path: webRoutes.home,
    element: <Redirect />,
    errorElement: <ErrorPage />,
  },

  //Auth Routes
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: webRoutes.login,
        element: <Login />,
      },
    ],
  },

  //Protected Routes
  {
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: webRoutes.adminoverview,
        element: <Dashboard />,
      },
      {
        path: webRoutes.usermanagement,
        element: <Users />,
      },

      {
        path: webRoutes.addcompnay,
        element: <UploadDocumentLlama />,
      },
      {
        path: webRoutes.processes,
        element: <Processes />,
      },
      {
        path: webRoutes.allCompanydatabase,
        element: <Documents type="all" />,
      },
      {
        path: webRoutes.verifiedCompanydatabase,
        element: <Documents type="verified" />,
      },
      {
        path: webRoutes.unverifiedCompanydatabase,
        element: <Documents type="unverified" />,
      },
      {
        path: webRoutes.leiimportscompanies,
        element: <LeiImportCompanies />,
      },
      {
        path: webRoutes.reportsandfeedback,
        element: <Reports />,
      },
      {
        path: webRoutes.settings,
        element: <Settings />,
      },
      {
        path: webRoutes.companies,
        element: <Companies />,
      },
      {
        path: webRoutes.customers,
        element: <Customers />,
      },
      {
        path: webRoutes.fullFlow,
        element: <FullFlow />,
      },
    ],
  },

  //User Detail
  {
    path: webRoutes.userDetail,
    element: (
      <RequireAuth>
        <UserDetail />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
  },

  //Customer Detail
  {
    path: webRoutes.customerDetail,
    element: (
      <RequireAuth>
        <CustomerDetail />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
  },

  // 404
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <ErrorPage />,
  },
]);
