import {
  FETCH_COMPANIES_WITHOUT_PDF_REQUEST,
  FETCH_COMPANIES_WITHOUT_PDF_SUCCESS,
  FETCH_COMPANIES_WITHOUT_PDF_FAIL,
  SCRAP_PDF_REQUEST,
  SCRAP_PDF_SUCCESS,
  SCRAP_PDF_FAIL,
} from "../constants/companiesConstants";
import { BASE_URL } from "../constants/constants";
import axios from "axios";

const BASE_URL_2 = process.env.REACT_APP_BASE_URL;

console.log("=====BASE_URL_2=====", BASE_URL_2);

// Action to fetch companies without PDFs
export const fetchCompaniesWithoutPDF = (
  page,
  limit = 10,
  company_name = ""
) => {
  console.log(page);
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_COMPANIES_WITHOUT_PDF_REQUEST });

      const response = await axios.get(
        `${BASE_URL}/api/document/companies-without-pdf`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            page: page,
            limit: limit,
            company_name: company_name, // Add company_name if filtering by it
          },
        }
      );
      // console.log(response)
      console.log(response.data.data.data);

      dispatch({
        type: FETCH_COMPANIES_WITHOUT_PDF_SUCCESS,
        payload: {
          data: response.data.data.data, // Company data
          total: response.data.data.total,
          // Total number of companies
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: FETCH_COMPANIES_WITHOUT_PDF_FAIL,
        payload: [{ message: error.message }],
      });
    }
  };
};

// Action to scrape PDFs

export const scrapPDFs = (
  companyName,
  year,
  user_id,
  selectedCountries,
  lei
) => {
  if (!selectedCountries) {
    selectedCountries = "";
  }

  if (!lei) {
    lei = "";
  }

  return async (dispatch) => {
    try {
      dispatch({ type: SCRAP_PDF_REQUEST });

      const response = await axios.post(
        `${BASE_URL_2}/api/document/scrap-pdfs`,
        {
          company_name: companyName,
          reporting_years: year,
          selectedCountries: selectedCountries,
          lei: lei,
          user_id: user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: SCRAP_PDF_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SCRAP_PDF_FAIL,
        payload: [{ message: error.message }],
      });
    }
  };
};
