import React, { useState, useEffect } from "react";
import { scrapPDFs } from "../../redux/methods/companiesMethods.js";
import {
  Button,
  Input,
  Upload,
  Typography,
  Modal,
  Spin,
  Select,
  message,
  // Option
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { AiFillCheckCircle, AiFillAlert } from "react-icons/ai";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants.js";
import { supabase } from "../../config/supabase.js";
import COUNTRY_MAPPING from "../../utils/Countries.js";

const { Title, Paragraph } = Typography;
const { Option } = Select;

export default function Flow() {
  // States
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [year, setYear] = useState("");
  const [yearInputError, setYearInputYearError] = useState("");

  // New states for PDF Gather
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastProcessingCheck, setLastProcessingCheck] = useState(null);

  // States for the countries
  const [suggestions, setSuggestions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // pdfGather toast state
  const [pdfGatherTastModal, setPdfGatherTastModal] = useState(0);

  const countryOptions = Object.entries(COUNTRY_MAPPING).map(
    ([fullName, code]) => ({
      value: fullName,
      label: fullName,
      code: code,
    })
  );

  // States and funcntion for the  for the Lei Inputs
  const [leiNumber, setLeiNumber] = useState("");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCompanyNameChange = (e) => {
    const newValue = e.target.value;
    // check if the country is selected or not.

    if (selectedCountries.length === 0) {
      // show an error.
      toast.error("Please select a country first.");
      return;
    }
    setCompanyName(newValue);
    setSuggestions([]);
    setLeiNumber("");
  };

  const handleSuggestionSelect = async (suggestion) => {
    setCompanyName(suggestion.entityLegalName);
    // await fetchLEINumber(suggestion);
    setLeiNumber(suggestion?.LEI || "");
    setSuggestions([]);
  };

  const handleFetchLeiSuggestions = async () => {
    try {
      // Clear suggestions and stop loading if input is empty
      if (companyName.trim() === "" || companyName.length < 0) {
        setSuggestions([]);
        setLoading(false);
        return;
      }

      // Start building the query
      let query = supabase
        .from("lei_companies")
        .select("entityLegalName, LEI")
        .ilike("entityLegalName", `%${companyName}%`);

      // Apply country filter if both country and entity name are provided
      if (selectedCountries && selectedCountries.length > 0) {
        const countryCode = COUNTRY_MAPPING[selectedCountries];
        if (countryCode) {
          query = query.eq("country", countryCode);
        }
      }

      // Execute the query
      const { data, error } = await query;

      if (error) {
        // toast.error("Failed to fetch LEI suggestions");
        return;
      }

      if (!data || data.length === 0) {
        setSuggestions([]);
        return;
      }

      // Update the suggestions
      // setSuggestions(data.map((item) => item.entityLegalName));
      setSuggestions(data);
      setLoading(false);
    } catch (error) {
      toast.error("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  // useEffect for filtering the LEI Company through country
  useEffect(() => {
    if (companyName.length !== 0 && companyName.trim() !== "") {
      handleFetchLeiSuggestions();
    }
    if (companyName.length === 0 && companyName.trim !== "") {
      console.log(companyName.length);
      setLoading(false);
    }
  }, [selectedCountries, companyName]);

  /* *************************************************************************************************/

  const validatingYear = (year) => {
    if (year === "") {
      setYearInputYearError("Year is required.");
      return false;
    }

    const parseYear = parseInt(year);
    if (isNaN(parseYear) || parseYear < 2015 || parseYear > 2025) {
      setYearInputYearError(
        "Invalid year. Please enter a year between 2015 and 2025."
      );
      return false;
    }

    setYearInputYearError("");
    return true;
  };

  const handleFileChange = (info, type) => {
    const fileList = info.fileList.slice(-2);
    setFileList(fileList);

    const files = fileList.map((file) => file.originFileObj);
    setFiles(files);
  };

  // const handleUploadDocument = async () => {
  //   if (!files.length || !companyName || !year) {
  //     console.log("File, Company Name, and Year are required.");
  //     return;
  //   }

  //   // Validate the year
  //   const validYear = validatingYear(year);
  //   if (!validYear) {
  //     console.log("Invalid year. Please enter a year between 2015 and 2025.");
  //     return;
  //   }

  //   // Prepare FormData
  //   const formData = new FormData();
  //   files.forEach((file, index) => {
  //     formData.append(`file${index + 1}`, file);
  //   });
  //   formData.append("companyName", companyName);
  //   formData.append("year", year);
  //   if (leiNumber) formData.append("lei", leiNumber);
  //   if (selectedCountries) formData.append("country", selectedCountries);

  //   try {
  //     setLoading(true); // Set loading state
  //     const response = await fetch(
  //       `${BASE_URL}/api/document/cteye-extraction`,
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     const data = await response.json(); // Await the JSON response

  //     if (!response.ok) {
  //       throw new Error(data.message || "Extraction request failed");
  //     }

  //     // Show success message
  //     message.success({
  //       content:
  //         data.message ||
  //         "Extraction request added. View database in 30 minutes.",
  //       duration: 5,
  //     });

  //     handleReset(); // Reset the form
  //   } catch (error) {
  //     console.error(error);
  //     // Show error message
  //     message.error({
  //       content:
  //         error.message || "An error occurred during the extraction request.",
  //       duration: 5,
  //     });
  //   } finally {
  //     setLoading(false); // Reset loading state
  //   }
  // };

  const handleReset = () => {
    setFiles([]);
    setFileList([]);
    setCompanyName("");
    setYear("");
    setSelectedCountries([]);
    setLeiNumber("");
  };

  // Updated PDF Gather Status Check Method
  const checkPDFLogStatus = async () => {
    try {
      const { data, error } = await supabase
        .from("pdf_gatherer_v2")
        .select("full_flow_status, job_processed");

      if (error) {
        console.log("Error checking PDF log status:", error);

        return;
      }
      if (data[0].full_flow_status === "PROCESSING") {
        setPdfGatherTastModal(1);
      }

      if (
        (data &&
          data[0] &&
          data[0].full_flow_status === "COMPLETED" &&
          data[0].job_processed === "100%") ||
        data[0].full_flow_status === "ERROR" ||
        data[0].full_flow_status === null
      ) {
        setIsProcessing(false);
        handleReset();
        setPdfGatherTastModal(2);
        pdfGatherTastModal === 2 &&
          toast.success("PDF Gather Completed Successfully");
      } else {
        // Continue processing
        setIsProcessing(true);
      }
    } catch (error) {
      setIsProcessing(true);
    }
  };

  useEffect(() => {
    checkPDFLogStatus();
  }, []);

  useEffect(() => {
    let intervalId;

    if (isProcessing) {
      intervalId = setInterval(() => {
        checkPDFLogStatus();
      }, 5000);
    }

    // Cleanup interval
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isProcessing]);

  // Modify handlePDFGather to ensure processing state is set
  const handlePDFGather = async () => {
    if (!companyName || !year) {
      toast.error("Company Name and Year are required");
      return;
    }

    let id;
    try {
      dispatch(scrapPDFs(companyName, year, id, selectedCountries, leiNumber));

      setIsProcessing(true);
    } catch (error) {
      toast.error("Failed to start PDF Gather");
      setIsProcessing(false);
    }
  };
  const terminatedPdfGather = async () => {
    try {
      // Step 1: Fetch the existing row
      const { data, error: fetchError } = await supabase
        .from("pdf_gatherer_v2")
        .select("id") // Adjust column name if needed
        .limit(1) // Since there's only one row
        .single(); // Ensures it returns a single object, not an array

      if (fetchError) {
        console.log("Error Fetching PDF Gather Row", fetchError);
        return;
      }

      // Step 2: Update the single row
      const { error: updateError } = await supabase
        .from("pdf_gatherer_v2")
        .update({ full_flow_status: "ERROR", job_processed: "0%" })
        .eq("id", data.id); // Use the fetched id

      if (updateError) {
        console.log("Error Terminating PDF Gather", updateError);
        return;
      }

      setIsProcessing(false);
      handleReset();
    } catch (error) {
      console.log("Error Terminating PDF Gather", error);
    }
  };

  return (
    <div className="bg-white p-6 text-[#000000] font-normal  h-auto">
      <div>
        <div className="flex items-center">
          <Title
            className="text-left font-inter-tight text-[22px] font-bold leading-[33px] "
            level={3}
          >
            <span>Full Flow</span>
          </Title>

          <span
            className={`text-sm mx-4 font-medium  mb-3 ${" text-[#008000] px-3 py-1 rounded-md"}`}
          >
            <span className="flex items-center">
              <AiFillCheckCircle className="mr-1" /> System is Available
            </span>
          </span>
        </div>
        <Paragraph className="text-left font-inter text-[15px] font-light   w-5/6 mb-6">
          To create a company record in the database, provide the legal entity
          name, upload the sustainability/annual report, and reporting year.
        </Paragraph>
        <div className="pt-4 space-y-8">
          <div className="pt-2">
            <label className="block text-left font-inter-tight text-[16px] font-bold leading-[24px] mb-4">
              Select origin country for the company:
            </label>
            <Select
              showSearch
              style={{ width: "50%" }}
              placeholder="Select origin country for PDF gathering"
              value={selectedCountries}
              onChange={setSelectedCountries}
              className="w-3/6 h-12 border-none rounded-md font-poppins text-[16px] text-[#5d5d5d] font-normal leading-[24px] text-left bg-[#F8F8F8]"
              optionLabelProp="label"
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownStyle={{
                zIndex: 30,
              }}
            >
              {countryOptions.map((country) => (
                <Option
                  key={country.value}
                  value={country.value}
                  label={country.label}
                >
                  <div className="flex items-center">{country.label}</div>
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <label className="block text-left font-inter-tight text-[16px] font-bold leading-[24px] mb-4">
              Legal entity name:
            </label>
            <div className="relative w-3/6">
              <Input
                className="w-full py-2 px-3 border border-gray-300 rounded-md font-poppins text-[16px] text-[#5d5d5d] font-normal leading-[24px] text-left bg-[#F8F8F8] focus:border-orange-500 focus:outline-none focus:ring-0"
                placeholder="Enter the name of the company"
                value={companyName}
                onChange={(e) => {
                  setLoading(true); // Set loading to true when typing starts
                  handleCompanyNameChange(e);
                }}
                allowClear
              />

              {/* Show message while loading */}
              {loading && companyName.length !== 0 ? (
                <div className="px-4 py-2 text-center text-gray-500">
                  Searching company name in the LEI database...
                </div>
              ) : suggestions.length > 0 && companyName.length !== 0 ? (
                <>
                  <div className="sticky top-0 px-4 py-2 font-bold text-gray-500 bg-white">
                    Select a Company
                  </div>
                  <div className="overflow-auto border border-gray-300 max-h-48">
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={suggestion.LEI ?? index}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSuggestionSelect(suggestion)}
                      >
                        {suggestion.entityLegalName}
                      </div>
                    ))}
                  </div>
                </>
              ) : companyName.length !== 0 ? (
                <div className="px-4 py-2 text-center text-gray-500">
                  No company Name found in LEI database
                </div>
              ) : null}
            </div>
          </div>

          <div className="pt-2">
            <label className="block text-left font-inter-tight text-[16px] font-bold leading-[24px] mb-4">
              LEI Number
            </label>
            <Input
              className="w-3/6 py-2 px-3 border border-gray-300 rounded-md font-poppins text-[16px] text-[#5d5d5d] font-normal leading-[24px] text-left bg-[#F8F8F8] focus:border-orange-500 focus:outline-none focus:ring-0"
              placeholder="Enter the LEI Number (Optional)"
              value={leiNumber}
              onChange={(e) => setLeiNumber(e.target.value)}
            />
          </div>

          <div className="pt-2">
            <label className="block text-left font-inter-tight text-[16px] font-bold leading-[24px] mb-4">
              Enter reporting year
            </label>
            <Input
              className={`w-3/6 py-2 px-3 border ${
                yearInputError ? "border-red-500" : "border-gray-300"
              } rounded-md font-poppins text-[16px] text-[#5d5d5d] font-normal leading-[24px] text-left bg-[#F8F8F8] focus:border-orange-500 focus:outline-none focus:ring-0`}
              placeholder="Enter the reporting year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
            {yearInputError && (
              <p className="mt-2 text-sm text-red-500">{yearInputError}</p>
            )}
          </div>

          <div className="flex justify-end pt-12 mt-4 space-x-4">
            <Button
              className="w-[194px] h-[45px] rounded-md bg-[#171733] text-white font-inter-tight text-[16px] font-bold"
              onClick={handleReset}
              disabled={loading || (!companyName && !year && !files.length)}
            >
              Reset
            </Button>

            <Button
              className="w-[194px] h-[45px] rounded-md bg-[#FF6B00] text-white font-inter-tight text-[16px] font-bold"
              onClick={handlePDFGather}
              disabled={loading || (!companyName && !year)}
            >
              Full Flow
            </Button>
          </div>
        </div>
      </div>

      <Modal
        visible={isProcessing}
        closable={true}
        footer={null}
        centered
        onCancel={terminatedPdfGather}
      >
        <div className="p-8 text-center">
          <Spin size="large" />
          <h3 className="mt-6 text-xl font-semibold font-inter-tight">
            Finding ESG Report
          </h3>
          <p className="mt-4">
            {`Our scraping tools are currently trying to find the most relevant 
      and accurate ESG report for ${companyName} for the year ${year}. This 
      process may take up to 10 minutes as we will find multiple documents 
      and use our AI to select the most accurate one.`}
          </p>
        </div>
      </Modal>
    </div>
  );
}
