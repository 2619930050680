import axios from "axios"
import { BASE_URL } from "../../redux/constants/constants"
import { supabase } from "../../config/supabase";

export const getCompanyChangeReports = async (reportId) => {
  try {
    console.log("Report Id : ", reportId)
    const res = await axios.post(`${BASE_URL}/api/document/get-all-change-reports`,
      {
        page: 1,
        limit: 9999999999,
        report_id: reportId
      }
    );

    console.log("Company change reports : ", res.data.data)
    return res.data.data

  } catch (error) {
    console.log("Error when fetching company change reports : ", error)
    return []
  }
}


export const getCurrentCompany = async (companyId) => {
  try {
    const { data, error } = await supabase.from("reports")
      .select("*")
      .eq("id", companyId)
      .single();


    return data;
  } catch (error) {
    return undefined;
  }
}