import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";
import BasePageContainer from "../layout/PageContainer";
import { useDispatch, useSelector } from "react-redux";
import { webRoutes } from "../../routes/web";
import { 
  Button, 
  message, 
  List, 
  Typography, 
  Input, 
  Spin, 
  Modal, 
  Tabs, 
  Table, 
  Form 
} from "antd";
import { 
  EditOutlined, 
  DeleteOutlined, 
  DragOutlined,
  SaveOutlined 
} from "@ant-design/icons";
import { getStructuredDocuments } from "../../redux/methods/documentMethods";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { Title } = Typography;
const { TabPane } = Tabs;

const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <a href={webRoutes.adminoverview}>Admin Overview</a>,
    },
    {
      key: webRoutes.uploadDocument,
      title: <a href={webRoutes.addcompnay}>Add Company</a>,
    },
  ],
};

export default function Settings() {
  // Original state variables
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingQuestion, setEditingQuestion] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  // New state variables for formatted columns
  const [formattedColumns, setFormattedColumns] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [editFormattedColumnId, setEditFormattedColumnId] = useState(null);
  const [editFormattedColumnName, setEditFormattedColumnName] = useState("");
  const [isFormattedColumnModalVisible, setIsFormattedColumnModalVisible] = useState(false);
  
  // New state variables for column order
  const [columnOrder, setColumnOrder] = useState({});
  const [columnOrderId, setColumnOrderId] = useState(null);
  const [loadingColumnOrder, setLoadingColumnOrder] = useState(false);
  const [orderedColumns, setOrderedColumns] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  
  // Create a mapping to quickly look up formatted names
  const [formattedNameMapping, setFormattedNameMapping] = useState({});

  const dispatch = useDispatch();
  const { documents } = useSelector(
    (state) => state.getStructuredDocumentsReducer
  );

  useEffect(() => {
    dispatch(getStructuredDocuments());
    fetchOptions();
    
    // Always fetch formatted columns first, we need this data for both tabs
    fetchFormattedColumns();
    
    if (activeTab === "3") {
      fetchColumnOrder();
    }
  }, [dispatch, activeTab]);

  // Update the mapping whenever formattedColumns changes
  useEffect(() => {
    const mapping = {};
    formattedColumns.forEach(column => {
      mapping[column.original_name] = column.formatted_name;
    });
    setFormattedNameMapping(mapping);
  }, [formattedColumns]);

  const fetchOptions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/document/get-added-reasons-for-reporting-dropdown`
      );
      if (response.data && response.data.status === true) {
        setOptions(response.data.data);
      } else {
        message.error("Failed to load options");
      }
    } catch (error) {
      message.error("Failed to load options");
    } finally {
      setLoading(false);
    }
  };

  const fetchFormattedColumns = async () => {
    setLoadingColumns(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/document/formateDataPointsName`
      );
      if (response.data && response.data.status === true) {
        setFormattedColumns(response.data.data);
      } else {
        message.error("Failed to load formatted columns");
      }
    } catch (error) {
      message.error("Failed to load formatted columns");
    } finally {
      setLoadingColumns(false);
    }
  };

  const fetchColumnOrder = async () => {
    setLoadingColumnOrder(true);
    try {
      // Make sure we have the formatted columns first
      if (formattedColumns.length === 0) {
        await fetchFormattedColumns();
      }
      
      const response = await axios.get(
        `${BASE_URL}/api/document/userDataPointOrder`
      );
      if (response.data && response.data.status === true) {
        const orderData = response.data.data.user_order_col;
        setColumnOrder(orderData);
        setColumnOrderId(response.data.data.id);
        
        // Check if there are new columns that aren't in the user_order_col yet
        const newOrderData = { ...orderData };
        let needsUpdate = false;
        
        // Find the max order value currently used
        let maxOrder = 0;
        Object.values(orderData).forEach(order => {
          if (order > maxOrder) maxOrder = order;
        });
        
        // Add any missing columns from formattedColumns
        formattedColumns.forEach(column => {
          if (!orderData.hasOwnProperty(column.original_name)) {
            maxOrder++;
            newOrderData[column.original_name] = maxOrder;
            needsUpdate = true;
          }
        });
        
        // If we added new columns, save the updated order
        if (needsUpdate) {
          setColumnOrder(newOrderData);
          saveNewColumnOrder(newOrderData, response.data.data.id);
        }
        
        // Convert the object to an array for rendering
        const columnsArray = Object.entries(newOrderData).map(([key, value]) => {
          // Use the formatted name from the mapping if available, otherwise use a formatted version of the key
          const displayName = formattedNameMapping[key] || 
            key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
          
          return {
            id: key,
            originalName: key,
            name: displayName,
            order: value
          };
        }).sort((a, b) => a.order - b.order);
        
        setOrderedColumns(columnsArray);
      } else {
        message.error("Failed to load column order");
      }
    } catch (error) {
      message.error("Failed to load column order");
    } finally {
      setLoadingColumnOrder(false);
    }
  };

  // Function to save new columns added to order
  const saveNewColumnOrder = async (newOrder, id) => {
    try {
      await axios.post(
        `${BASE_URL}/api/document/userDataPointOrder`,
        {
          id: id,
          user_order_col: newOrder
        }
      );
    } catch (error) {
      message.error("Failed to save new columns");
    }
  };

  // Existing handlers
  const handleAddQuestion = async () => {
    if (newQuestion.trim()) {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/document/adding-reasons-for-reporting-dropdown`,
          {
            question: newQuestion,
          }
        );
        message.success("Question added successfully");
        setOptions((prev) => [...prev, response.data]);
        fetchOptions();
        setNewQuestion("");
      } catch (error) {
        message.error("Failed to add question");
      }
    } else {
      message.warning("Question cannot be empty");
    }
  };

  const handleUpdateQuestion = async () => {
    if (editingQuestion.trim()) {
      try {
        await axios.post(
          `${BASE_URL}/api/document/adding-reasons-for-reporting-dropdown`,
          {
            id: editingQuestionId,
            question: editingQuestion,
          }
        );
        message.success("Question updated successfully");
        setOptions((prev) =>
          prev.map((option) =>
            option.id === editingQuestionId
              ? { ...option, question: editingQuestion }
              : option
          )
        );
        setIsModalVisible(false);
        setEditingQuestionId(null);
        setEditingQuestion("");
      } catch (error) {
        message.error("Failed to update question");
      }
    } else {
      message.warning("Question cannot be empty");
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/document/delete-added-reason-for-reporting-dropdown/${id}`
      );
      message.success("Question deleted successfully");
      setOptions((prev) => prev.filter((option) => option.id !== id));
    } catch (error) {
      message.error("Failed to delete question");
    }
  };

  const handleEditClick = (id, question) => {
    setEditingQuestionId(id);
    setEditingQuestion(question);
    setIsModalVisible(true);
  };

  // New handlers for formatted columns
  const handleUpdateFormattedColumn = async () => {
    if (editFormattedColumnName.trim()) {
      try {
        await axios.post(
          `${BASE_URL}/api/document/formateDataPointsName`,
          {
            id: editFormattedColumnId,
            formatted_name: editFormattedColumnName,
          }
        );
        message.success("Column name updated successfully");
        
        // Update the formatted columns data
        const updatedColumns = formattedColumns.map((column) =>
          column.id === editFormattedColumnId
            ? { ...column, formatted_name: editFormattedColumnName }
            : column
        );
        setFormattedColumns(updatedColumns);
        
        // Also update the formatting in the ordered columns if they're loaded
        if (orderedColumns.length > 0) {
          const originalName = formattedColumns.find(col => col.id === editFormattedColumnId)?.original_name;
          if (originalName) {
            const updatedOrderedColumns = orderedColumns.map(col => 
              col.originalName === originalName
                ? { ...col, name: editFormattedColumnName }
                : col
            );
            setOrderedColumns(updatedOrderedColumns);
          }
        }
        
        setIsFormattedColumnModalVisible(false);
        setEditFormattedColumnId(null);
        setEditFormattedColumnName("");
      } catch (error) {
        message.error("Failed to update column name");
      }
    } else {
      message.warning("Column name cannot be empty");
    }
  };

  const handleEditFormattedColumnClick = (id, formatted_name) => {
    setEditFormattedColumnId(id);
    setEditFormattedColumnName(formatted_name);
    setIsFormattedColumnModalVisible(true);
  };

  // Drag and drop handlers for column order
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(orderedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the order values
    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setOrderedColumns(updatedItems);
  };

  const saveColumnOrder = async () => {
    try {
      // Convert the array back to the required object format
      const newOrder = {};
      orderedColumns.forEach((column, index) => {
        newOrder[column.originalName] = index;
      });

      await axios.post(
        `${BASE_URL}/api/document/userDataPointOrder`,
        {
          id: columnOrderId,
          user_order_col: newOrder
        }
      );
      
      message.success("Column order saved successfully");
      setColumnOrder(newOrder);
    } catch (error) {
      message.error("Failed to save column order");
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "2" && formattedColumns.length === 0) {
      fetchFormattedColumns();
    } else if (key === "3") {
      fetchColumnOrder();
    }
  };

  // Columns for formatted columns table
  const formattedColumnsTableColumns = [
    {
      title: 'Original Name',
      dataIndex: 'original_name',
      key: 'original_name',
     
    },
    {
      title: 'Formatted Name',
      dataIndex: 'formatted_name',
      key: 'formatted_name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleEditFormattedColumnClick(record.id, record.formatted_name)}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Reporting Reasons" key="1">
          <div className="px-6 pt-2 bg-white rounded-md">
            <h3 className="text-xl font-semibold mb-6 text-gray-800 capitalize font-inter-tight">
              Manage Reporting Reasons
            </h3>
            
            <div className="flex items-center space-x-4 mb-6">
              <input
                type="text"
                placeholder="Enter a new reason"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                className="border border-gray-300 focus:border-orange-500 focus:ring-0 outline-none w-full sm:w-2/3 px-4 py-2 rounded-md"
              />
              
              <button
                className="bg-[#171733] text-white px-6 py-2 rounded-md hover:bg-gray-900 transition"
                onClick={handleAddQuestion}
              >
                Add Reason
              </button>
            </div>
          </div>

          <Spin spinning={loading}>
            <div className="bg-white rounded-lg">
              <List
                dataSource={options}
                renderItem={(item, index) => (
                  <List.Item
                    className={`flex justify-between items-center font-inter hover:bg-[#FF6B00] group ${
                      index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                    }`}
                    actions={[
                      <Button
                        type="link"
                        onClick={() => handleEditClick(item.id, item.question)}
                        icon={<EditOutlined className="group-hover:text-white" />}
                        className="transition group-hover:text-white"
                      >
                        <span className="group-hover:underline">Edit</span>
                      </Button>,
                      <Button
                        key="delete"
                        type="link"
                        onClick={() => handleDeleteQuestion(item.id)}
                        icon={<DeleteOutlined className="group-hover:text-white" />}
                        className="transition group-hover:text-white"
                      >
                        <span className="group-hover:underline">Delete</span>
                      </Button>,
                    ]}
                  >
                    <span className="text-gray-700 group-hover:text-white">
                      {item.question}
                    </span>
                  </List.Item>
                )}
              />
            </div>
          </Spin>
        </TabPane>
        
        <TabPane tab="Column Names" key="2">
          <div className="px-6 pt-2 bg-white rounded-md">
            <h3 className="text-xl font-semibold mb-6 text-gray-800 capitalize font-inter-tight">
              Manage Column Display Names
            </h3>
            
            <Spin spinning={loadingColumns}>
              <Table
                dataSource={formattedColumns}
                columns={formattedColumnsTableColumns}
                rowKey="id"
                pagination={{ pageSize: 10 }}
                className="formatted-columns-table"
              />
            </Spin>
          </div>
        </TabPane>
        
        <TabPane tab="Column Order" key="3">
          <div className="px-6 pt-2 bg-white rounded-md">
            <h3 className="text-xl font-semibold mb-6 text-gray-800 capitalize font-inter-tight">
              Manage Column Display Order
            </h3>
            
            <div className="mb-4 flex justify-end">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={saveColumnOrder}
                className="bg-[#171733] hover:bg-gray-900 border-0"
              >
                Save Order
              </Button>
            </div>
            
            <Spin spinning={loadingColumnOrder}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="bg-white rounded-lg"
                    >
                      {orderedColumns.map((column, index) => (
                        <Draggable key={column.id} draggableId={column.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`flex items-center p-3 border-b ${
                                index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                              } hover:bg-gray-100`}
                            >
                              <div className="mr-3 text-gray-500">
                                <DragOutlined />
                              </div>
                              <div className="flex-1">
                                <div className="font-medium">{column.name}</div>
                                {/* <div className="text-xs text-gray-500">Original: {column.originalName}</div> */}
                                <div className="text-xs text-gray-500">Order: {column.order}</div>


                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Spin>
          </div>
        </TabPane>
      </Tabs>

      {/* Modal for editing reporting reasons */}
      <Modal
        title="Update Question"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingQuestionId(null);
          setEditingQuestion("");
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsModalVisible(false);
              setEditingQuestionId(null);
              setEditingQuestion("");
            }}
          >
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            style={{ backgroundColor: "black", borderColor: "black" }}
            onClick={handleUpdateQuestion}
          >
            Update
          </Button>,
        ]}
      >
        <Input
          value={editingQuestion}
          onChange={(e) => setEditingQuestion(e.target.value)}
          placeholder="Enter the new question"
        />
      </Modal>

      {/* Modal for editing formatted column names */}
      <Modal
        title="Update Column Name"
        visible={isFormattedColumnModalVisible}
        onCancel={() => {
          setIsFormattedColumnModalVisible(false);
          setEditFormattedColumnId(null);
          setEditFormattedColumnName("");
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsFormattedColumnModalVisible(false);
              setEditFormattedColumnId(null);
              setEditFormattedColumnName("");
            }}
          >
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            style={{ backgroundColor: "black", borderColor: "black" }}
            onClick={handleUpdateFormattedColumn}
          >
            Update
          </Button>,
        ]}
      >
        <Input
          value={editFormattedColumnName}
          onChange={(e) => setEditFormattedColumnName(e.target.value)}
          placeholder="Enter the new formatted name"
        />
      </Modal>
    </BasePageContainer>
  );
}