import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
} from "../constants/authConstants";
import { supabase } from "../../config/supabase";

export const adminLogin = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_LOGIN_REQUEST });

      // Check if Admin Exists
      const { data: isAdmin, error: isAdminError } = await supabase
        .from("admin")
        .select("*")
        .eq("email", email);

      if (isAdminError || !isAdmin || isAdmin.length === 0) {
        dispatch({
          type: ADMIN_LOGIN_FAIL,
          payload: [{ message: "Admin not found or database error" }],
        });
        return;
      }

      // Authenticate Admin
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        dispatch({
          type: ADMIN_LOGIN_FAIL,
          payload: [{ message: error.message }],
        });
        return;
      }

      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: { userId: data.user.id },
      });
    } catch (error) {
      console.error("Login error:", error);
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: [{ message: "An unexpected error occurred" }],
      });
    }
  };
};
