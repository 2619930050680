import { createClient } from "@supabase/supabase-js";

const supabaseURL = process.env.REACT_APP_SUPABASE_URL;
const supabaseAPI = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseURL) {
  throw new Error("supabaseURL key not found");
}

if (!supabaseAPI) {
  throw new Error("supabaseAPI key not found");
}

export const supabase = createClient(supabaseURL, supabaseAPI);
