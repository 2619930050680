const COUNTRY_MAPPING = {
  "United States": "US",
  "United Kingdom": "GB",
  Canada: "CA",
  Australia: "AU",
  India: "IN",
  Germany: "DE",
  France: "FR",
  Japan: "JP",
  China: "CN",
  Russia: "RU",
  "South Africa": "ZA",
  Brazil: "BR",
  Italy: "IT",
  Spain: "ES",
  Mexico: "MX",
  Netherlands: "NL",
  "Saudi Arabia": "SA",
  Sweden: "SE",
  Switzerland: "CH",
  Singapore: "SG",
  "New Zealand": "NZ",
  "South Korea": "KR",
  Ireland: "IE",
  Argentina: "AR",
  Norway: "NO",
  Denmark: "DK",
  Poland: "PL",
  Portugal: "PT",
  Belgium: "BE",
  Austria: "AT",
  Finland: "FI",
  Greece: "GR",
  Thailand: "TH",
  Malaysia: "MY",
  Philippines: "PH",
  Indonesia: "ID",
  Vietnam: "VN",
  Pakistan: "PK",
  Bangladesh: "BD",
  Egypt: "EG",
  Israel: "IL",
  Chile: "CL",
  Colombia: "CO",
  Peru: "PE",
  Taiwan: "TW",
  "Hong Kong": "HK",
  Iceland: "IS",
  Luxembourg: "LU",
  Monaco: "MC",
  Liechtenstein: "LI",
  Malta: "MT",
  "Czech Republic": "CZ",
  Slovakia: "SK",
  Hungary: "HU",
  Romania: "RO",
  Bulgaria: "BG",
  Croatia: "HR",
  Slovenia: "SI",
  Estonia: "EE",
  Latvia: "LV",
  Lithuania: "LT",
  Cyprus: "CY",
  Ukraine: "UA",
  Belarus: "BY",
  Kazakhstan: "KZ",
  Uzbekistan: "UZ",
  Azerbaijan: "AZ",
  Georgia: "GE",
  Armenia: "AM",
  Turkey: "TR",
  "United Arab Emirates": "AE",
  Qatar: "QA",
  Kuwait: "KW",
  Oman: "OM",
  Bahrain: "BH",
  Iraq: "IQ",
  Iran: "IR",
  Syria: "SY",
  Jordan: "JO",
  Lebanon: "LB",
  Palestine: "PS",
  Yemen: "YE",
  Afghanistan: "AF",
  "North Korea": "KP",
  Bhutan: "BT",
  Nepal: "NP",
  Maldives: "MV",
  "Sri Lanka": "LK",
  Myanmar: "MM",
  Cambodia: "KH",
  Laos: "LA",
  Mongolia: "MN",
  Fiji: "FJ",
  "Papua New Guinea": "PG",
  Vanuatu: "VU",
  Tonga: "TO",
  Samoa: "WS",
  "Solomon Islands": "SB",
  Kiribati: "KI",
  "Marshall Islands": "MH",
  Micronesia: "FM",
  Palau: "PW",
  Nauru: "NR",
  Tuvalu: "TV",
  "Vatican City": "VA",
  Andorra: "AD",
  "San Marino": "SM",
  Montenegro: "ME",
  Macedonia: "MK",
  "Bosnia and Herzegovina": "BA",
  Serbia: "RS",
  Kosovo: "XK",
  Albania: "AL",
  Moldova: "MD",
  Belize: "BZ",
  Barbados: "BB",
  "Trinidad and Tobago": "TT",
  Jamaica: "JM",
  Bahamas: "BS",
  Cuba: "CU",
  Haiti: "HT",
  "Dominican Republic": "DO",
  "Saint Lucia": "LC",
  "Saint Vincent and the Grenadines": "VC",
  "Saint Kitts and Nevis": "KN",
  "Antigua and Barbuda": "AG",
  Dominica: "DM",
  Grenada: "GD",
  // Adding missing territories and dependencies

  Jersey: "JE",
  Guernsey: "GG",
  "Isle of Man": "IM",
  Bermuda: "BM",
  "Cayman Islands": "KY",
  Gibraltar: "GI",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  Greenland: "GL",
  "Puerto Rico": "PR",
  "American Samoa": "AS",
  Guam: "GU",
  "U.S. Virgin Islands": "VI",
  "Northern Mariana Islands": "MP",
  "French Polynesia": "PF",
  "New Caledonia": "NC",
  "British Virgin Islands": "VG",
  "Turks and Caicos Islands": "TC",
  Anguilla: "AI",
  Montserrat: "MS",
  "Sint Maarten": "SX",
  Aruba: "AW",
  Curaçao: "CW",
  "French Guiana": "GF",
  Martinique: "MQ",
  Guadeloupe: "GP",
  Réunion: "RE",
  Mayotte: "YT",

  // Adding more independent countries that were missing
  "Timor-Leste": "TL",
  Brunei: "BN",
  "Cape Verde": "CV",
  Comoros: "KM",
  Djibouti: "DJ",
  Eritrea: "ER",
  Ethiopia: "ET",
  Gabon: "GA",
  Gambia: "GM",
  Ghana: "GH",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Kenya: "KE",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Madagascar: "MG",
  Malawi: "MW",
  Mali: "ML",
  Mauritania: "MR",
  Mauritius: "MU",
  Morocco: "MA",
  Mozambique: "MZ",
  Namibia: "NA",
  Niger: "NE",
  Nigeria: "NG",
  Rwanda: "RW",
  "São Tomé and Príncipe": "ST",
  Senegal: "SN",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Somalia: "SO",
  "South Sudan": "SS",
  Sudan: "SD",
  Swaziland: "SZ",
  Tanzania: "TZ",
  Togo: "TG",
  Tunisia: "TN",
  Uganda: "UG",
  Zambia: "ZM",
  Zimbabwe: "ZW",

  // Central Asian countries
  Kyrgyzstan: "KG",
  Tajikistan: "TJ",
  Turkmenistan: "TM",

  // South American countries
  Ecuador: "EC",
  Guyana: "GY",
  Paraguay: "PY",
  Suriname: "SR",
  Uruguay: "UY",
  Venezuela: "VE",

  // Central American countries
  "Costa Rica": "CR",
  "El Salvador": "SV",
  Guatemala: "GT",
  Honduras: "HN",
  Nicaragua: "NI",
  Panama: "PA",
};

module.exports = { COUNTRY_MAPPING };
