
import React from "react";
//Components
import BasePageContainer from "../layout/PageContainer";
import Flow from "./FullFlow";
export default function FullFlow() {
  return (
    <BasePageContainer >
    
       <Flow/>
      
     
    </BasePageContainer>
  );
}
